<template>
          <div class="min-h-full flex flex-col justify-center items-center" :style="`background-image: url('`+bgImageUrl+`')`">
            <v-loading :is_loading="isloading" />
           <div class="w-full md:w-1/2 px-5 py-5 md:px-10">
                   <div class="shadow-2xl rounded-lg hover:shadow-2xl transition duration-500 ease-out" >
                        <div class="px-2 py-2 md:px-10 bg-black bg-opacity-70 rounded-md">
                            <div class="py-3" >
                            <v-stepper alt-labels class="shadow-none mt-2" v-model="stepper" elevation="0" style="background-color: transparent;" >
                                <v-stepper-header>
                                    <v-stepper-step :complete="issaveuser" color="pink lighten-1" :step="1">
                                        <span class="text-white font-sans">Información</span>
                                    </v-stepper-step>
                                     <v-divider class="bg-gray-400 transition duration-300 ease-in"></v-divider>
                                     <v-stepper-step  :complete="issaveaddress" color="pink lighten-1" :step="2">
                                       <span class="text-white font-sans">Dirección</span> 
                                    </v-stepper-step>
                              
                                </v-stepper-header>
                            <v-stepper-items>  
                             <v-stepper-content step="1" class="px-10 py-1" > 
                                <div  v-if="step = 1">      
                                    <div class="px-5">
                                        <small class="text-gray-100 tracking-wider">Información general</small>
                                        <v-divider class="bg-gray-500"></v-divider>
                                    </div>      
                                    <div class="grid grid-cols-1 gap-10 gap-y-2 px-0 md:px-5 md:grid-cols-2 py-2">
                                        <div>
                                                <label class="text-base font-sans text-gray-100">Nombre(s)</label>
                                                <input v-model="$v.user.name.$model" type="text" class="input-full-dark" style="" placeholder="Ingrese el nombre" />
                                                <small class="text-error-dark" v-show="$v.user.name.$anyDirty && !$v.user.name.required">* El nombre es requerido</small>
                                        </div>
                                        <div>
                                                <label class="text-base font-sans text-gray-100">Primer Apellido</label>
                                                <input v-model="$v.user.last_name.$model" type="text" class="input-full-dark" placeholder="Ingrese el apellido" />
                                                <small class="text-error-dark" v-show="$v.user.last_name.$anyDirty && !$v.user.last_name.required">* El apellido es requerido</small>
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-1 gap-10 gap-y-2 px-0 md:px-5 md:grid-cols-2 py-2">
                                            <div>
                                                    <label class="text-base font-sans text-gray-100">Segundo Apellido</label>
                                                    <input v-model="$v.user.second_lastname.$model" type="text" class="input-full-dark" placeholder="Ingrese el apellido" />                            
                                                    <small class="text-error-dark" v-show="$v.user.second_lastname.$anyDirty && !$v.user.second_lastname.required">* El apellido es requerido</small>
                                            </div>
                                            <div>
                                                    <label class="text-base font-sans text-gray-100">Fecha de Nacimiento</label>
                                                    <div class="flex gap-2 items-center">
                                                      <masked-input v-model="$v.user.birthdate.$model" :mask="'####-##-##'" type="text" class="input-full-dark" placeholder="Ingrese la fecha"/>
                                                    <v-menu
                                                        ref="menu"
                                                        v-model="menu"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="date"
                                                        transition="scale-transition"
                                                        persistent
                                                        width="290px"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <div  v-bind="attrs" v-on="on">
                                                                <svg   xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-calendar cursor-pointer text-gray-400 hover:text-control-dark-blue-light" viewBox="0 0 16 16">
                                                                    <path  d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                                                                </svg>
                                                            </div>
                                                        </template>
                                                        <v-date-picker
                                                        v-model="date"
                                                        no-title
                                                        scrollable
                                                        dark
                                                        landscape
                                                        header-color="blue darken-4"
                                                        >
                                                        <v-spacer></v-spacer>
                                                        <v-btn
                                                            text
                                                            color="primary"
                                                            @click="menu = false"
                                                        >
                                                            Cancel
                                                        </v-btn>
                                                        <v-btn
                                                            text
                                                            color="primary"
                                                            @click="$refs.menu.save(date)"
                                                        >
                                                            OK
                                                        </v-btn>
                                                        </v-date-picker>
                                                    </v-menu>
                                                    </div>
                                                    <small class="text-error-dark" v-show="$v.user.birthdate.$anyDirty && !$v.user.birthdate.required">* El fecha es requerida</small>
                                            </div>
                                    </div>
                                    <div class="grid grid-cols-1 gap-10 gap-y-2 px-0 md:px-5 md:grid-cols-2 py-2">
                                         <div>
                                                    <label class="text-base font-sans text-gray-100">Genero</label>
                                                    <v-autocomplete
                                                    color="white"
                                                    v-model="$v.user.id_cat_sex.$model"
                                                    :disabled="!catsexcat"
                                                    :items="catsexcat"
                                                    filled
                                                    solo
                                                    hide-details
                                                    no-data-text="..."
                                                    placeholder="Genero"
                                                    clearable
                                                    item-text="description"
                                                    item-value="id"
                                                    background-color="#323546"
                                                    item-color="white"
                                                    dark
                                                    >
                                                    <template v-slot:item="data">
                                                            <template v-if="(typeof data.item !== 'object')">
                                                            <v-list-item-content v-text="data.item.name"></v-list-item-content>
                                                            </template>
                                                            <template v-else >
                                                                <v-list-item-avatar>
                                                                    <f-icon class="text-gray-500" :icon="['fas', data.item.icon ? data.item.icon : 'user']" />
                                                                </v-list-item-avatar>
                                                                <v-list-item-content>
                                                                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                                    <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </template>
                                                    </template>
                                                    </v-autocomplete>


                                                    <small class="text-error-dark" v-show="$v.user.id_cat_sex.$anyDirty && !$v.user.id_cat_sex.required">* El genero es requerido</small>
                                            </div> 
                                            <!-- <div>
                                                    <label class="text-base font-sans text-gray-100">Dirección</label>
                                                    <input v-model="$v.user.address.$model" type="text" class="input-full-dark" placeholder="Ingrese la dirección" />
                                                    <small class="text-error-dark" v-show="$v.user.address.$anyDirty && !$v.user.address.required">* La dirección es requerida</small>
                                            </div> -->
                                    </div>
                                    <div class="px-0 md:px-5">
                                        <small class="text-gray-400 tracking-wider">Información de contacto</small>
                                        <v-divider class="bg-gray-500"></v-divider>
                                    </div>
                                    <div class="grid grid-cols-1 gap-10 gap-y-2 px-0 md:px-5 md:grid-cols-2 py-2">
                                            <div>
                                                    <label class="text-base font-sans text-gray-100">Teléfono</label>
                                                    <masked-input v-model="$v.user.phone.$model" mask="(111)-111-1111" maskChar=" " :masked="false" type="text" class="input-full-dark" placeholder="Ingrese el teléfono" />
                                                    <small class="text-error-dark" v-show="$v.user.phone.$anyDirty && !$v.user.phone.required">* El teléfono es requerido</small>
                                            </div>
                                    </div>
                                    <div class="px-0 md:px-5">
                                        <small class="text-gray-400 tracking-wider">Credenciales</small>
                                        <v-divider class="bg-gray-500"></v-divider>
                                    </div>
                                    <div class="grid grid-cols-1 gap-10 gap-y-2 px-0 md:px-5 md:grid-cols-2 py-2">
                                            <div>
                                                    <label class="text-base font-sans text-gray-100">Correo Electrónico</label>
                                                    <input v-model="$v.user.email.$model" type="email" class="input-full-dark" placeholder="Ingrese el correo" />                            
                                                    <small class="text-error-dark" v-show="$v.user.email.$anyDirty && !$v.user.email.required">* El correo es requerido</small>
                                            </div>
                                            <div>
                                                    <label class="text-base font-sans text-gray-100">Contraseña</label> 
                                                    <div class="flex gap-3 items-center">
                                                    <input :type="!ispwd ? 'password': ''" v-model="$v.user.password.$model" class="input-full-dark" placeholder="Ingrese la contraseña" />          
                                                        <svg @click="ispwd = !ispwd" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" :class="!ispwd ? 'text-gray-400' : 'text-blue-700'" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                                        </svg>

                                                    </div>
                                                    <small class="text-error-dark" v-show="$v.user.password.$anyDirty && !$v.user.password.required">* La contraseña es requerida</small>
                                            </div>                        
                                    </div>                      
                                </div>
                                </v-stepper-content>
                                 <v-stepper-content step="2" class="px-5 md:px-10 py-1"> 
                                      <div>  
                                          
                                    <div v-if="!issaveaddress" class="transition-all duration-300 ease-in-out">
                                        <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5">
                                            <div>
                                            <label class="text-base font-sans text-gray-100">País</label>
                                            <v-auto-complete
                                                v-model="$v.address.idCountry.$model"
                                                :vmodel="address.idCountry"
                                                :values="countries"
                                                :vtext="'name'"
                                                :vvalue="'id'"
                                                :title="'name'"
                                                :isdisabled="true"
                                                dark
                                                />
                                                <small class="text-error-dark" v-show="$v.address.idCountry.$anyDirty && !$v.address.idCountry.required">* El pais es requerido</small>
                                            </div>
                                            <div>
                                                <label class="text-base font-sans text-gray-100">Estado</label>
                                                <v-auto-complete
                                                    v-model="$v.address.idState.$model"
                                                    :vmodel="address.idState"
                                                    :values="states"
                                                    :vtext="'name'"
                                                    :vvalue="'id'"
                                                    :title="'name'"
                                                    :disabled="!states"
                                                    dark
                                                    />
                                                    <small class="text-error-dark" v-show="$v.address.idState.$anyDirty && !$v.address.idState.required">* El estado es requerido</small>
                                            </div>
                                        </div>
                                        <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5">
                                            <div>
                                                <label class="text-base font-sans text-gray-100">Municipio</label>
                                                <v-auto-complete
                                                    v-model="$v.address.idMunicipality.$model"
                                                    :vmodel="address.idMunicipality"
                                                    :values="municipalities"
                                                    :vtext="'name'"
                                                    :vvalue="'id'"
                                                    :title="'name'"
                                                    dark
                                                    />
                                                    <small class="text-error-dark" v-show="$v.address.idMunicipality.$anyDirty && !$v.address.idMunicipality.required">* El municipio es requerido</small>
                                            </div>
                                            <div>
                                                <label class="text-base font-sans text-gray-100">Código Postal</label>
                                                <v-auto-complete
                                                    v-model="$v.address.idPostalCode.$model"
                                                    :vmodel="address.idPostalCode"
                                                    :values="postcodes"
                                                    :vtext="'namecode'"
                                                    :vvalue="'id'"
                                                    :title="'namecode'"
                                                    dark
                                                    />
                                                    <small class="text-error-dark" v-show="$v.address.idPostalCode.$anyDirty && !$v.address.idPostalCode.required">* El código postal es requerido</small>
                                            </div>
                                        </div>
                                        <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5">
                                            <div>
                                                    <label class="text-base font-sans text-gray-100">Colonia</label>
                                                    <input :disabled="isloading" v-model="$v.address.suburb.$model" class="input-full-dark"  placeholder="Ingrese la colonia" />
                                                    <small class="text-error-dark" v-show="$v.address.suburb.$anyDirty && !$v.address.suburb.required">* La colonia es requerida</small>
                                                </div>
                                                <div>
                                                    <label class="text-base font-sans text-gray-100">Calle</label>
                                                    <input :disabled="isloading" v-model="$v.address.street.$model" class="input-full-dark"  placeholder="Ingrese la calle" />
                                                    <small class="text-error-dark" v-show="$v.address.street.$anyDirty && !$v.address.street.required">* La calle es requerida</small>
                                                </div>
                                        </div>
                                        <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5">
                                            <div>
                                                    <label class="text-base font-sans text-gray-100">Número Exterior</label>
                                                    <input :disabled="isloading" v-model="$v.address.outdoorNumber.$model" class="input-full-dark"  placeholder="Ingrese el número" />
                                                    <small class="text-error-dark" v-show="$v.address.outdoorNumber.$anyDirty && !$v.address.outdoorNumber.required">* El número es requerido</small>
                                                </div>
                                                <div>
                                                    <label class="text-base font-sans text-gray-100">Número Interior</label><span class="text-gray-400 text-xs">(opcional)</span>
                                                    <input :disabled="isloading" v-model="$v.address.interiorNumber.$model" class="input-full-dark"  placeholder="Ingrese el número" />
                                                    <small class="text-error-dark" v-show="$v.address.interiorNumber.$anyDirty && !$v.address.interiorNumber.required">* El número es requerido</small>
                                                </div>
                                        </div>

                                </div>
                                <div v-if="issaveaddress &&  issaveuser" class="transition-all duration-300 ease-in-out">
                                    <div>
                                        <div class="grid md:flex gap-2 md:gap-5 justify-center items-center">
                                            <label class="text-white tracking-wide font-sans text-2xl font-bold text-center">BIENVENIDO A</label>
                                            <label class="text-fucsia-lighter tracking-wide font-sans text-2xl font-bold text-center">REBYU</label>
                                        </div>
                                    </div>
                                    <div class="grid justify-center py-3 gap-3  sm:px-0 md:px-10">
                                        <div class="flex justify-center">
                                             <lottie-animation
                                                :path="'lottie/sendemail.json'" 
                                                :width="250"
                                                :height="250"     
                                                :speed="1"     
                                            />
                                        </div>
                                        <div>
                                            <p class="text-gray-50 font-sans text-center tracking-wide" >Tu cuenta se ah creado correctamente, se ah enviado un link a tu correo electrónico para confirmar la cuenta y puedas disfrutar de Rebyu.</p>
                                        </div>
                                         <div class="grid gap-3">
                                            <button class="btn-full-gray">
                                                Reenviar correo
                                            </button>
                                            <router-link to="/home">
                                                <button class="btn-full-fucsia">
                                                    Explorar
                                                </button>
                                            </router-link>
                                       </div>
                                    </div>

                                </div>
                                 </div>
                                 </v-stepper-content>  
                               
                            </v-stepper-items>
                           </v-stepper>                         
                            </div>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <div class="w-full md:w-auto grid md:flex  md:justify-end items-center gap-5 gap-y-2">
                               <div class="hidden md:block" v-if="!issaveaddress">
                                    <button class="btn-full-gray" @click="onCancel()">
                                        Cancelar
                                    </button>
                               </div>
                                <div v-if="stepper>1  && (!issaveaddress)">
                                    <button class="btn-full-gray" @click="onBack()">
                                       Anterior
                                    </button>
                                </div>
                                <div v-if="!issaveaddress">
                                    <button class="btn-full-fucsia" @click="onNext()">
                                       {{stepper != 2 ? 'Siguiente' : 'Finalizar'}}
                                    </button>
                                </div>
                                <div class="block md:hidden" v-if="!issaveaddress">
                                    <button class="btn-full-gray" @click="onCancel()">
                                        Cancelar
                                    </button>
                               </div>
                               
                            </div>
                            </v-card-actions>
                        </div>
                       

                   </div>
                </div>
          </div>
          


</template>

<script>

import User from '../../models/auth/user.model';
import AuthUser from '../../models/auth/auth.model';
import {validationMixin} from 'vuelidate';
import globalMixin from '../../mixins/globalMixin';
import ToastMixin from '@/mixins/vue/toastmixin';
/* eslint-disable */
import _service from '../../services/HSuite/HCore/auth/auth.service'
import { CatSexService } from '../../services/HSuite/Context/hsuite-core-context'
import {  requiredIf } from 'vuelidate/lib/validators';
import {mapActions} from "vuex";
import Address from '../../models/user/address.model';
import { LocationService, AddressService } from '../../services/HSuite/Context/hsuite-core-context';
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";



export default {
    name : "Register",
    mixins: [validationMixin, globalMixin, ToastMixin],
    props: {
        id_role: {
            type: String,
            default: ""
        }
    },
    components: {
        LottieAnimation
    },
    data: function() {
        return {
            isloading: false,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString(),
            user: new User(),
            Auth : new AuthUser(),
            isdate: false,
            ispwd: false,
            catsexcat : [],
            address: new Address(),
            countries: [], 
            states: [], 
            municipalities: [], 
            postcodes: [],
            menu: false,
            bgImageUrl: require('@/assets/login_bg.jpg'),
            stepper: 1,
            id_user_response: '',
            issaveuser: false,
            issaveaddress: false
        }
    },

    validations :{
        user: {
            name: {
                required:requiredIf(function() {
                    return this.stepper == 1 ? true : false;
                })
            },
            last_name: {
                required: requiredIf(function() {
                    return this.stepper == 1 ? true : false;
                })
            },
            second_lastname: {
                required :requiredIf(function() {
                    return this.stepper == 1 ? true : false;
                })
            },
            birthdate: {
                required :requiredIf(function() {
                    return this.stepper == 1 ? true : false;
                })
            },
            id_cat_sex: {
                required :requiredIf(function() {
                    return this.stepper == 1 ? true : false;
                })
            },
            phone: {
                required :requiredIf(function() {
                    return this.stepper == 1 ? true : false;
                })
            },
            email: {
                required : requiredIf(function() {
                    return this.stepper == 1 ? true : false;
                })
            },
            password: {
                required :requiredIf(function() {
                    return this.stepper == 1 ? true : false;
                })
            },
            id_role: {
                required : requiredIf(function() {
                    return this.stepper == 1 ? true : false;
                })
            }
        },
        address: {
             idCountry: {
                 required :  requiredIf(function() {
                    return this.stepper == 2 ? true : false;
                })
             },
             idState: {
                 required:  requiredIf(function() {
                    return this.stepper == 2 ? true : false;
                })
             },
             idMunicipality: {
                 required:  requiredIf(function() {
                    return this.stepper == 2 ? true : false;
                })
             },
             idPostalCode: {
                 required:  requiredIf(function() {
                    return this.stepper == 2 ? true : false;
                })
             },
             codePostal: {
                 required  :  requiredIf(function() {
                    return this.stepper == 2 ? true : false;
                })
             },
             suburb: {
                 required :  requiredIf(function() {
                    return this.stepper == 2 ? true : false;
                })
             },
             street: {
                 required :  requiredIf(function() {
                    return this.stepper == 2 ? true : false;
                })
             },
             outdoorNumber: {
                 required :  requiredIf(function() {
                    return this.stepper == 2 ? true : false;
                })
             },
             interiorNumber: {
                 required: false
             },
             coordinates: {
                 required :  requiredIf(function() {
                    return this.stepper == 2 ? false : false;
                })
             },
             latitude: {
                 required :  requiredIf(function() {
                    return this.stepper == 2 ? false : false;
                })
             },
             length: {
                 required :  requiredIf(function() {
                    return this.stepper == 2 ? false : false;
                })
             },
             id_user: {
                 required :  requiredIf(function() {
                    return this.stepper == 2 ? true : false;
                })
             }
         }
    },


    created(){
        this.Init();
    },

    methods:{

        Init : async function() {
            this.getCatSex();
        },

        ...mapActions({
            sigIn: 'auth/sigIn'
            }),


            getCatSex: async function() {
                let response = await CatSexService.getAll();
                if(response.success) {
                    this.catsexcat = response.data;
                }
            },

        Registrar : async function(){
            try {
                this.$v.$touch();
                if(this.$v.$anyError) return;
            
                if(! await this.confirm('Registrar', '¿Desea registrarse en Rebyu?')) return;
                
                // SE REGISTRA EL USUARIO EN EL CORE
                this.isloading = true;
                let response = await _service.register(this.user);
                this.isloading = false;
                if(response.success){
                    this.success(response.message);
                    this.id_user_response = response.data.iduser;
                    this.issaveuser = true;
                    this.stepper += 1;
                }
                else{
                    this.failure(response.message);
                }

            } catch (error) {
                console.error(error);
            }
        },
        saveAddress: async function() {
            this.address.id_user = this.id_user_response || '';

            this.$v.$touch();
            if(!this.$v.$anyError) {
                this.isloading = true;
                let response = await AddressService.registerAddress(this.address);
                this.isloading = false;
                if(response.success) {
                    this.success(response.message);
                    this.issaveaddress = true;
                    await this.sendEmailConfirm();
                }else {
                    this.failure(response.message);
                }
            }
        },

        onNext: async function(){
            if(this.stepper == 1 && !this.issaveuser) {
                this.user.id_role = this.id_role;
                this.$v.$touch();
                if(this.$v.$anyError) return;
                await this.Registrar();
               
            }else if(this.stepper == 2 && !this.issaveaddress) {
                await this.saveAddress();
            }else {
                    this.stepper = this.stepper +1;
            }
        },
        onBack: function(){
            this.stepper = this.stepper - 1;
        },
        initAddress: async function() {
            this.isloading = true;
            await this.getCountry();
            this.isloading = false;
        },
        getCountry: async function() {
            let response = await LocationService.getCountry();
            if(response.success) {
                this.countries = response.data;
                this.address.idCountry =  this.countries[0].id;
            }
        },
        getState: async function(id) {
            this.isloading = true;
            let response = await LocationService.getState(id);
            this.isloading = false;
            if(response.success) {
                this.states = response.data;
            }
        },
        getMunipality: async function(id) {
            this.isloading = true;
            let response = await LocationService.getMunicipality(id);
            this.isloading = false;
            if(response.success) {
                this.municipalities = response.data;
            }
        },
        getPostalCodes: async function(id, code) {
            this.isloading = true;
            let response = await LocationService.getPostalCodes(id, code);
            this.isloading = false;
            if(response.success) {
                this.postcodes = response.data;
            }
        },
        onCancel: function() {
            this.$router.go(-1);
        },
        sendEmailConfirm: async function() {
            let data = {
                iduser: this.id_user_response || ''
            }
            this.isloading = true;
            let response = await _service.sendLinkConfirm(data);
            this.isloading = false;
            if(response.success) {
                this.issendLink = true;
            }else {
                this.stepper =  4;
                this.failure('Error al enviar el correo de confirmación');
            }
         },
    },
    computed: {
         mucipality_code: function() {
            let code = this.municipalities.find(x => x.id == this.address.idMunicipality);
            return code ? code.code : '';
        },
    },
    watch: {
        date: function() {
            this.user.birthdate = this.date;
        },
        stepper: function() {
            if(this.stepper == 2) {
                this.initAddress();
            }
        },
        'address.idCountry' : function() {
             if(!this.address.idCountry) {
                this.states = [];
            }
            if(this.address.idCountry) {
                this.getState(this.address.idCountry);
            }
        },
        'address.idState' : function() {
             if(!this.address.idState) {
                this.municipalities = [];
                this.postcodes = [];
                this.address.idMunicipality = '';
                this.codePostal = '';
                this.idPostalCode = '';
            }
            if(this.address.idState) {
                this.getMunipality(this.address.idState);
            }
        },
        'address.idMunicipality': function() {
             if(!this.address.idMunicipality) {
                this.postcodes = [];
                this.codePostal = '';
                this.idPostalCode = '';
            }
            if(this.address.idMunicipality) {
                this.getPostalCodes(this.address.idMunicipality, this.mucipality_code);
            }
        },
        'address.idPostalCode' : function() {
             if(!this.address.idPostalCode) {
                 this.address.suburb = '';
            }
            if(this.address.idPostalCode) {
                let code = this.postcodes.find(x => x.id == this.address.idPostalCode);
                this.address.suburb = code.name;
                this.address.codePostal = code.code;
            }
        }
    }
}

</script>

<style scoped>
.v-stepper__header {
    box-shadow: none !important;
}

.v-input--selection-controls {
    margin-top: 5px !important;
    padding-top: 2px !important;
}

.v-input__slot {
    align-items: center;
    color: inherit;
    display: flex;
    margin-bottom: 0px !important;
    min-height: inherit;
    position: relative;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    width: 100%;
}


</style>